<template>
  <router-view />
</template>
<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import router from "./router";
import Pusher from "pusher-js";
import axios from "axios";

export default {
  setup() {
    let store = useStore();
    const authdata = localStorage.getItem("auth");
    if (authdata) {
      // console.log("working");
      store.dispatch("setAuth", JSON.parse(authdata));
    }
    onMounted(async (_) => {
      if (localStorage.getItem("api")) {
        store.dispatch("setCustomerApi", localStorage.getItem("api"));
      }

      let app_key = "188dd8eaf377e22b0c89";
      let cluster = "us2";
      var pusher = new Pusher(app_key, {
        cluster: cluster,
      });
      let user_code = store.state.auth.authUser.user_code;
      let channel = pusher.subscribe("pos-" + user_code);
      channel.bind("orders", (data) => {
        // console.log("mi p kwar");
        console.log(data);
        try {
          document.getElementById(`${data}`).click();
          return;
        } catch (error) {
          console.log(error);
        }
      });

      // from scanner machine
      var barcode = "";
      var interval;
      document.addEventListener("keydown", function (evt) {
        if (interval) clearInterval(interval);
        if (evt.code == "Enter") {
          if (barcode) handleBarcode(barcode);
          barcode = "";
          return;
        }
        if (evt.key.length == 1) {
          // console.log("shift lr p");
          barcode += evt.key;
        }
        interval = setInterval(() => (barcode = ""), 20);
      });
      function handleBarcode(scanned_barcode) {
        console.log(scanned_barcode);
        document.getElementById(`${scanned_barcode}`).click();
      }
      // let token = store.state.auth.token;
      // let response = await axios.get(store.getters.getCustomerApi.shopInfoApi, {
      //   headers: { Authorization: `Bearer ${token}` },
      // });
      // let payload = {
      //   shop_name: response.data.name,
      //   shop_phone: response.data.phone,
      //   shop_address: response.data.address,
      //   voucher_photo: response.data.voucher_photo == "no" ? false : response.data.voucher_photo,
      //   shop_photo: response.data.shop_photo == "no" ? false : response.data.shop_photo,
      // };
      // let condition = response.data.voucher_type ? true : false;

      // store.dispatch("setIsReceipt", condition);
      // store.dispatch("setShopName", payload);
    });
  },
  components: {},
};
</script>
<style lang="scss"></style>
