<template>
  <div class="row print-hide">
    <div class="col-12">
      <nav class="navbar navbar-expand-lg navbar-light bg-light vh-8">
        <div class="container-fluid">
          <a class="navbar-brand fw-bold" href="javascript:void(0)">{{
            shop_name
          }}</a>
          <div
            class="navbar-nav align-items-center"
            v-if="$route.name == 'home'"
          >
            <div class="nav-item navbar-search-wrapper mb-0">
              <a
                class="nav-item nav-link search-toggler px-0"
                href="javascript:void(0);"
              >
                <div class="input-group border rounded shadow">
                  <input
                    v-model="keyword"
                    type="text"
                    class="form-control"
                    placeholder="Product Name Or Code"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                  />
                  <button
                    type="button"
                    class="btn rounded-pill btn-icon btn-label-primary"
                  >
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </a>
            </div>
          
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
              <!-- <li class="nav-item navbar-dropdown dropdown-user dropdown me-3 hover">
                <i class="bi bi-gear h5" @click="passcodeRequest"></i>

              </li> -->
              <li class="nav-item navbar-dropdown dropdown-user dropdown me-2">
                <div>
                  <select name="paytype" v-model="paymethod" @change="handleFilter()" id="" class="form-select">
                    <option value="0">Please Select</option>
                    <!-- <option v-for="today_payment in today_payments" :key="today_payment" :value="today_payment.payment_method">
                      <span v-if="today_payment.payment_method == '1'">Cash</span>
                      <span v-else-if="today_payment.payment_method == '2'">Bank</span>
                      <span v-else-if="today_payment.payment_method == '3'">Kpay</span>
                    </option> -->
                    <option :value="1">Cash</option>
                    <option :value="2">Bank</option>
                    <option :value="3">Kpay</option>
                  </select>

                </div>
              </li>
              <li class="nav-item navbar-dropdown dropdown-user dropdown" style="width: 350px;">
                <b > Daily Total Sales : </b>
                  <span v-for="t in today_allpayments" :key="t">{{ t.total_payment != null ? t.total_payment : 0 }} MMK</span> <br>
                <b v-if="total"> Total Sales By {{ paymethod === 1 ? 'CASH' : (paymethod === 2 ? 'BANK' : 'KPAY') }} Payment: </b>
                  <span v-if="total">
                    <span v-for="payment in total" :key="payment">
                      {{ payment.total_payment != '' ? payment.total_payment : '0' }}</span> MMK
                    </span>
                  <span v-else><b>Total Sales By Payment :  </b> 0 MMK </span>

                <!-- <div>
                  <b v-if="total">Daily Total Refunds: </b>
                  <span v-if="total">
                    <span v-for="refund in total" :key="refund">
                      {{ refund.total_refund != null ? refund.total_refund : 0 }}</span> MMK
                  </span>
                  <span v-else><b> Daily Total Refunds :  </b> 0 MMK </span>
                </div> -->
                
              </li>
              <li class="nav-item navbar-dropdown dropdown-user dropdown">
                <a
                  class="nav-link dropdown-toggle hide-arrow"
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div class="avatar avatar-online">
                    
                    <img
                      :src="shop_img ?? './logo.png'"
                      alt=""
                      class="rounded-circle"
                    />
                  </div>
                </a>
              </li>
              <li class="nav-item ms-1">
                <button
                  type="button"
                  class="btn btn-label-danger"
                  @click="handleLogout"
                >
                  Logout<i class="bi bi-box-arrow-right ms-3"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { computed } from "@vue/reactivity";
import { confirm } from "@/composables/useConfirm";
import { ref,onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { requestPasscode } from "@/composables/useRequestPasscode";
import { alert } from "@/composables/useAlert";

export default {
  setup() {
    let store = useStore();
    let router = useRouter();
    let keyword = ref(store.state.order.keyword);
    let token = useStore().state.auth.token;
    // let barcode = ref(store.state.order.barcode);
    let authUser = store.state.auth.authUser;
    const shop_name = computed(() => store.state.order.shop_info.shop_name);
    const shop_img = computed(() => store.state.order.shop_info.shop_photo);
    

    let handleLogout = () =>
      confirm("Sure to logout?", "", () => {
        store.dispatch("logOut");
        store.dispatch("clearOrder");
        router.push({ name: "login" });
      });
    let passcodeRequest = async () => {
      let { passcode } = await requestPasscode();
      passcode.value == 111
        ? router.push({ name: "product-warehouse" })
        : alert("error", "Wrong Passcode", "");
    };

    const today_payments = ref('');
    const today_allpayments=ref('');
    const paymethod=ref(0);
    const total=ref('');

    onMounted(async () => {
      try{
      let today_payment_response = await axios.get(
        store.getters.getCustomerApi.totalPaymentApi,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      today_payments.value = today_payment_response.data.data;
      today_allpayments.value=today_payment_response.data.total_orders;
      // console.log("fsdfgrsrtg",today_allpayments.value);
    } catch (error) {
        console.error('Error fetching today orders:', error);
      }
    });

    function handleFilter(){
      // console.log('asdfasfa',paymethod.value)
      // console.log('fasfasfa',today_payments.value);
      if(Array.isArray(today_payments.value)){
        const filteredPayments = today_payments.value.filter(payment => {
          return payment.payment_method === paymethod.value;
      });
      total.value=filteredPayments;
      console.log('total',filteredPayments)
      } else {
        console.error("today_payments is not an array");
     }
    }

    watch(keyword, (_) => store.dispatch("setKeyword", keyword.value));

    return {
      today_payments,
      today_allpayments,
      handleLogout,
      authUser,
      keyword,
      passcodeRequest,
      shop_name,
      shop_img,
      paymethod,
      total,
      handleFilter
    };
    
  }
};
</script>
