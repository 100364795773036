<template>
  <Layout>
    <div class="col-2 custom-scroll bg-custom rounded">
      <CategoryList @categoryChange="changeCategory" />
    </div>
    <div class="col-5 pb-3 custom-scroll">
      <span class="badge rounded-pill bg-label-primary mb-3" v-if="searchKeyword">
        Search result for : {{ searchKeyword }}
      </span>
      <ProductList :products="products" v-if="!isCategoryLoading" />
      <div v-else class="w-100 vh-60 d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    </div>
    <div class="col-5 ps-0 position-relative custom-scroll">
      <Orders />
    </div>
    <SalePriceModal :key="Math.random()" />
  </Layout>
</template>

<script>
import Orders from "../components/Home/Orders";
import Loader from "../components/Misc/Loader.vue";
import CategoryList from "../components/Home/CategoryList";
import ProductList from "../components/Home/ProductList.vue";
import Layout from "@/components/layout/Layout.vue";
import axios from "axios";

import { onMounted, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";
import _ from "lodash";
import { useWebNotification } from "@vueuse/core";
import Pusher from "pusher-js";
import SalePriceModal from "@/components/Home/SalePriceModal.vue";
import da_DK from "@/frestui/assets/vendor/libs/formvalidation/dist/js/locales/da_DK";
export default {
  components: {
    Orders,
    Loader,
    CategoryList,
    Layout,
    ProductList,
    SalePriceModal,
  },
  setup() {
    let token = useStore().state.auth.token;
    let store = useStore();
    let products = ref([]);
    let currentCategory = ref("all");
    let isCategoryLoading = ref(false);
    let searchKeyword = ref("");
    let productsAll = computed(() => store.getters.getProducts);
    let changeCategory = (payload) => (currentCategory.value = payload);

    let fetchProducts = async (id) => {
      if (id == "all") {
        isCategoryLoading.value = true;
        let response = await axios.get(
          store.getters.getCustomerApi.allProductApi,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        let datas = response.data.data;
        // console.log('response product',response);
        // const filtereddatas =response.data.filter((e) =>
        //  e.category_id == '').map((e) => { return {name: e.name}});
        //  console.log('filterdatas',filtereddatas);

        let dataArrays = [...datas[""]];
       


        // for (let i = 0; i < dataArrays.length; i++) {
        //   console.log("dataArrays",dataArrays[i].category_id);
        //   if(dataArrays[i].category_id !== ''){
        //     products.value = dataArrays[i];
        //   }
        // }

        // let manipulated = dataArray.map((product) => {
        //   if (store.state.order?.warehouse_id != product.warehouse_id) {
        //     store.state.order.warehouse_id = product.warehouse_id;
        //   }
        //   return {
        //     id: product.id,
        //     name: product.name,
        //     sale_price: product.sale_price,
        //     purchase_price: product.purchase_price,
        //     wholesale_price: product.wholesale_price,
        //     left_qty: product.left_qty,
        //     barcode: product.barcode,
        //     category_id: product.category_id,
        //     count: 0,
        //     isNew: false,
        //     photo: product.photo.url
        //       ? product.photo.url
        //       : "/img/imgnotfound.png", //0 array coz photo and product is one to many
        //   };
        // });
        products.value=datas;
        // console.log('products in home',products.value);

        let mani = [];
        for (const key in datas) {
          datas[key].forEach((d) => (d != null ? (mani = [...mani, d]) : ""));
        }

        store.dispatch("addTmpOrder", mani);
        isCategoryLoading.value = false;
        store.dispatch("setCurrentCategoryId", "");
      } else {
        isCategoryLoading.value = true;
        store.dispatch("setCurrentCategoryId", id);
        // console.log(_);
        // let response = await axios.get(
        //   `${store.getters.getCustomerApi.productsByCategory}/${id}`,
        //   { headers: { Authorization: `Bearer ${token}` } }
        // );
        // let manipulated = response.data.data.map((product) => {
        //   return {
        //     id: product.id,
        //     name: product.name,
        //     sale_price: product.sale_price,
        //     purchase_price: product.purchase_price,
        //     wholesale_price: product.wholesale_price,
        //     left_qty: product.left_qty,
        //     count: 0,
        //     isNew: false,
        //     photo: product.photo?.url
        //       ? product.photo.url
        //       : "/img/imgnotfound.png", //0 array coz photo and product is one to many
        //   };
        // });
        // products.value = manipulated;
        isCategoryLoading.value = false;
      }
    };

    watch(currentCategory, () => fetchProducts(currentCategory.value));

    // search
    watchEffect((_) => {
      const keyword = store.getters.getKeyword;
      if (keyword !== "") {
        products.value = computed((_) =>
          store.state.order.tmpOrders.filter((tmpOrder) =>
            tmpOrder.name.toLowerCase().includes(keyword)
          )
        ).value;
        searchKeyword.value = keyword;
      } else {
        fetchProducts(currentCategory.value);
        searchKeyword.value = "";
      }
    });
    onMounted(async () => {
      fetchProducts(currentCategory.value);

      let token = store.state.auth.token;
      let response = await axios.get(store.getters.getCustomerApi.shopInfoApi, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let payload = {
        shop_name: response.data.name,
        shop_phone: response.data.phone,
        shop_address: response.data.address,
        voucher_photo:
          response.data.voucher_photo == "no"
            ? false
            : response.data.voucher_photo,
        shop_photo:
          response.data.shop_photo == "no" ? false : response.data.shop_photo,
      };
      // let condition = response.data.voucher_type ? true : false;

      // store.dispatch("setIsReceipt", condition);
      //get township
      let resposeCity = await axios.get(store.getters.getCustomerApi.getCity, {
        headers: { Authorization: `Bearer ${token}` },
      });

      let resposeTownship = await axios.get(
        store.getters.getCustomerApi.getTownship,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      store.dispatch("setCity", resposeCity.data.data);
      store.dispatch("setTownship", resposeTownship.data.data);
      store.dispatch("setShopName", payload);
    });
    return {
      changeCategory,
      products,
      isCategoryLoading,
      searchKeyword,
    };
  },
};
</script>

<style lang="scss" scoped></style>
